<mat-card-title>
    Alteração de senha
</mat-card-title>
<mat-dialog-content>
    <form [formGroup]="formPassword">
        <div class="row py-4">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Senha</mat-label>
                    <input matInput formControlName="password" id="password" name="password"
                           [type]="hidePass ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hidePass" type="button">
                        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="f.password.invalid">Campo obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Confirme a senha</mat-label>
                    <input matInput formControlName="confirmPassword" id="confirmPassword" name="confirmPassword"
                           [type]="hideConfirm ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideConfirm" type="button">
                        <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="formPassword?.invalid || f.confirmPassword.invalid">
                        As senhas não coincidem
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-action-row class="d-flex justify-content-center pt-4">
    <button mat-flat-button class="bg-color-success" (click)="validatePassword()"
            [class.spinner]="loading" [disabled]="loading">
        Alterar senha
    </button>
</mat-action-row>
