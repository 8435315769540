import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-restore',
  templateUrl: './button-restore.component.html'
})
export class ButtonRestoreComponent implements OnInit {
  ngOnInit(): void {
  }
}
