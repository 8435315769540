import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalSelectGoogleAccountComponent} from './components/modal-select-google-account/modal-select-google-account.component';
import {FieldExtraControl} from '../../../modules/insertions/modules/insertion-update/components/extras/components/components/fields/FieldExtraControl';
import {FormControl} from '@angular/forms';
import {InsertionExtraOptionsService} from '../../services/insertion-extra-options.service';
import {GoogleAdsAccount} from '../../models/google-ads-account';
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-account-google-field',
    templateUrl: './account-google-field.component.html'
})
export class AccountGoogleFieldComponent {

    @Output() changeEmitter = new EventEmitter<any>();
    public error: string = null;

    @Input() set adServer(adServer: string) {
        if (adServer) {
            this._adServer = adServer;
            this.getGoogleAccounts();
        }
    }

    @Input() required = true;
    @Input() accountControl: FormControl;
    private _adServer: string;
    public name = 'account';
    public label = 'Conta';
    public help = 'Conta do Google, clique para selecionar';

    private listAccounts: Array<GoogleAdsAccount> = [];

    constructor(private insertionExtraOptionService: InsertionExtraOptionsService,
                private dialog: MatDialog,
                private toastr: ToastrService) {
    }

    public openSelectAccount() {
        const dialogRef = this.dialog.open(ModalSelectGoogleAccountComponent, {
            width: '600px',
            data: {
                listAccounts: this.listAccounts
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result === 'disable') {
                    this.accountControl.disable();
                } else {
                    this.selected(result);
                }
            }
        });
    }

    private getGoogleAccounts() {
        this.insertionExtraOptionService.accountsGoogle(this._adServer).subscribe(data => {
            this.listAccounts = data;
            this.accountControl.enable();
        }, err => {
            switch (err.status) {
                case 422:
                    this.error = err.error.errors[0];
                    this.toastr.error(err.error.errors[0], 'GoogleAds:');
                    break;
                default:
                    this.error = 'Ocorreu um erro inesperado!';
                    this.toastr.error(this.error, 'GoogleAds:', {
                        timeOut: 15000
                    });
            }
            this.accountControl.disable();
        });
    }

    private selected(value) {
        this.accountControl.setValue(value);
        this.accountControl.updateValueAndValidity();
        this.changeEmitter.emit({control: FieldExtraControl.ACCOUNT, value: this.accountControl.value});
    }
}
