import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationEnd, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class LayoutService {
    private loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private router: Router, private http: HttpClient) {

        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart || event instanceof ActivationEnd) {
                this.setLoader(true);
            }
        });
    }

    setLoader(status: boolean): void {
        this.loaderStatus$.next(status);
    }

    getLoader(): Observable<boolean> {
        return this.loaderStatus$.asObservable();
    }

    getApiVersion() {
        const url = `${environment.url}/version`;
        return this.http.get<string>(url);
    }
}
