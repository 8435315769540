import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-sort-column',
    templateUrl: './sort-column.component.html',
    styleUrls: ['./sort-column.component.scss']
})

export class SortColumnComponent {
    public direction = '';

    @Input() public column: string;

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @Input()
    set clear(clear: string) {
        if (clear !== this.column) {
            this.direction = '';
        }
    }

    constructor() {
    }

    public change(direction: string = '') {
        this.direction = direction;
        this.onChange.emit({ column: this.column, direction: this.direction });
    }

}
