import { Injectable } from "@angular/core";
@Injectable()
export class Company {

    public static BBI_SOLUTIONS = 1;
    public static TALENT = 2;
    public static DP6 = 3;
    public static TRIBAL = 4;
    public static SANTANDER = 5;
    public static SUNO = 6;
    public static YR = 7;
    public static adAgency = 8;
    public static SANTANDER_ADMIN = 9;

    public id: number;
    public name: string;
    public initials: string;
    public created_at: string;
    public updated_at: string;
    public deleted_at: string;

}
