import {Component, EventEmitter, Input, Output} from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { LayoutService } from '../../../services/layout.service';


declare var document: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

    public version: string = environment.VERSION;
    public api_version = '';
    public ENV = environment;
    public year;
    private api_url: string = document.ROOT_API_URL;

    constructor(private layoutService: LayoutService) {
        this.year = new Date();

        layoutService.getApiVersion().subscribe((version: string) => {
            this.api_version = version;
        });
    }


    public showVersion() {
        Swal.fire({
            title: 'Versão',
            icon: 'info',
            html:
                `<div style="text-align: left">
                <strong>API URL: </strong> ${this.api_url} <br>
                <strong>SITE: </strong> ${this.version} <br>
                <strong>API: </strong> ${this.api_version} <br></div>`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
                'Continuar!'
        });

    }
}
