<mat-panel-title>
    <h2>Selecione uma conta - Google</h2>
</mat-panel-title>
<mat-dialog-content>
    <div>
        <mat-form-field class="w-100">
            <mat-label>Nome</mat-label>
            <input matInput type="text" id="search_name" name="search_name"
                   (keyup)="search()"
                   [(ngModel)]="searchName" autocomplete="off">
        </mat-form-field>
    </div>
    <div>
        <table class="mdl-data-table portal-table-tab-panel portal-table-tab-panel--active" id="contas">
            <thead>
            <tr class="text--left">
                <th>Ação</th>
            </tr>
            </thead>
            <tbody class="text--left" *ngIf="googleAccounts.length > 0 ; else empty">
            <tr *ngFor="let account of googleAccounts">
                <td>{{ ('|' + '&nbsp;'.repeat(10)).repeat(account?.level)  }}|____ {{ account?.name  }}</td>
                <td>
                    <button mat-stroked-button color="accent" [hidden]="!account?.can_select"
                            (click)="selectAccount(account)" type="button">
                        Selecionar
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #empty>
                <tbody>
                <tr>
                    <td class="text--center" colspan="6">Nenhum resultado encontrado!</td>
                </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</mat-dialog-content>
