import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortColumnComponent} from './components/sort-column/sort-column.component';
import {DndDirective} from './directives/drag-drop.directive';
import {SpecialCharacterDirective} from './directives/special-character.directive';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {PricePipe} from './pipes/price.pipe';
import {SubstrPipe} from './pipes/substr.pipe';
import {LowercaseDirective} from './directives/lowercase.directive';
import {AngularMaterialModule} from '../core/angular-material.module';
import {MatSelectComponent} from './components/mat-select/mat-select.component';
import {AccountGoogleFieldComponent} from './components/account-google-field/account-google-field.component';
import {AccountFieldComponent} from './components/account-field/account-field.component';
import {ModalSelectGoogleAccountComponent} from './components/account-google-field/components/modal-select-google-account/modal-select-google-account.component';
import {FilterGroupTable} from "./pipes/filter-group-table.pipe";
import { ButtonBackComponent } from './components/button-back/button-back.component';
import { ButtonRestoreComponent } from './components/button-restore/button-restore.component';
import { ModalPasswordComponent } from './components/modal-password/modal-password.component';
import { UserService } from './services/user.service';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AngularMaterialModule,
    ],
    declarations: [
        SubstrPipe,
        PricePipe,
        DateFormatPipe,
        FilterGroupTable,
        SpecialCharacterDirective,
        SortColumnComponent,
        DndDirective,
        LowercaseDirective,
        ModalPasswordComponent,
        ButtonBackComponent,
        ButtonRestoreComponent,
        MatSelectComponent,
        AccountFieldComponent,
        AccountGoogleFieldComponent,
        ModalSelectGoogleAccountComponent
    ],
    exports: [
        SubstrPipe,
        PricePipe,
        DateFormatPipe,
        FilterGroupTable,
        SpecialCharacterDirective,
        SortColumnComponent,
        DndDirective,
        AngularMaterialModule,
        FormsModule,
        ModalPasswordComponent,
        ButtonBackComponent,
        ButtonRestoreComponent,
        MatSelectComponent,
        AccountFieldComponent,
        AccountGoogleFieldComponent,
        ModalSelectGoogleAccountComponent
    ], providers: [
        UserService
    ]
})

export class SharedModule {
}

export const CustomCurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    thousands: '.',
    nullable: true
};
