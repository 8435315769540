import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})

export class AlertService {

    public alert: Observable<any>;
    private _alert: Subject<any>;

    constructor() {
        this._alert = new Subject<any>();
        this.alert = this._alert.asObservable();
    }

    callAlert() {
        this._alert.next();
    }

}
