<div>
    <div class="navbar">
        <app-navbar [sidebarOpened]="sidenav.opened" (toggleSidebar)="sidenav.toggle()"></app-navbar>
    </div>
    <mat-drawer-container class="content" autosize>

        <mat-drawer #sidenav mode="side" opened="true">
            <app-sidebar (toggleSidebar)="sidenav.toggle()"></app-sidebar>
        </mat-drawer>

        <mat-drawer-content>
            <router-outlet></router-outlet>
            <app-footer class="footer"></app-footer>
        </mat-drawer-content>

    </mat-drawer-container>
</div>
