import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertService } from './services/alert.service';
import { LayoutService } from './services/layout.service';
import { FooterComponent } from './layout/components/footer/footer.component';
import { LoaderComponent } from './layout/components/loader/loader.component';
import { SidebarComponent } from './layout/components/sidebar/sidebar.component';
import { TopToolbarAlertComponent } from './layout/components/navbar/components/top-toolbar-alert/top-toolbar-alert.component';
import { NavbarComponent } from './layout/components/navbar/navbar.component';
import { LayoutLoggedComponent } from './layout/layout-logged/layout-logged.component';
import { LayoutLogoutComponent } from './layout/layout-logout/layout-logout.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        LayoutLoggedComponent,
        LayoutLogoutComponent,
        FooterComponent,
        LoaderComponent,
        SidebarComponent,
        NavbarComponent,
        TopToolbarAlertComponent
    ],
    exports: [
        LoaderComponent
    ],
    providers: [
        LayoutService,
        AlertService
    ]
})

export class LayoutModule {
}
