import { Pipe, PipeTransform } from '@angular/core';
import { Group } from '../models/group';
import { FilterGroup } from '../models/filter-group';

@Pipe({ name: 'filterGroupTable', pure: false })
export class FilterGroupTable implements PipeTransform {

    transform(groups: Group[], filter: FilterGroup) {
        if (filter.chave_insercao ||
            filter.investment ||
            filter.platform_objective ||
            filter.hash ||
            filter.anuncio ||
            filter.grupo_anuncio ||
            filter.name) {
            const filtro = {};
            if (filter) {
                for (const key in filter) {
                    if (filter[key]) {
                        if (String(filter[key]).length > 0) {
                            Object.assign(filtro, { [key]: String(filter[key]).trim().toLowerCase() });
                        }
                    }
                }
            }
            function search(group: Group) {
                const keys = Object.keys(filtro);
                return keys.every((key) => {
                    if (filter.investment) {
                        return String(group[key]).toLowerCase().startsWith(filtro[key]);
                    }
                    return String(group[key]).toLowerCase().includes(filtro[key]);
                });
            }
            return groups.filter(search, filter);
        } else {
            return groups;
        }
    }

}
