import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OptionAdsystem } from 'src/app/shared/models/optionAdsystem';
import {ErrorHandler} from "../http/responses/error-handler";
import { environment } from 'src/environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { tap } from 'rxjs/operators';

declare var require: any;
const CryptoJS = require('crypto-js');

@Injectable({
    providedIn: 'root'
})

export class PiTypeService {

    public constructor(private http: HttpClient) {  }

    private setTypesSession(types: any) {
        sessionStorage.setItem('piTypes', CryptoJS.AES.encrypt(JSON.stringify(types), environment.SECRET_KEY));
    }

    private getTypesSession() {
        const typesObject = sessionStorage.getItem('piTypes');
        if (!typesObject) return typesObject;
        const types = CryptoJS.AES.decrypt(typesObject.toString(), environment.SECRET_KEY);
        return JSON.parse(types.toString(CryptoJS.enc.Utf8));
    }

    toOptions(data: Array<any>): Array<OptionAdsystem> {
        let options: Array<OptionAdsystem> = [];

        options =  data.map((elem) => {
            return {label:elem.name, value: String(elem.id), label_adsystem: elem.label_adsystem};
        });

        return options;
    }

    getTypes() {
        /*         const hasTypes = this.getTypesSession();
        if (hasTypes) {
            return new Observable<Array<any>>(
                (subscriber: Subscriber<Array<any>>) => {
                    subscriber.next(hasTypes)
                });
        } */

        const url = `${environment.urlMicro}/pi-types`;
        return this.http.get<Array<any>>(url)
    }
}
