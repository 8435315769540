import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appLowercase]'
})
export class LowercaseDirective {

  constructor(private el: ElementRef, @Optional() private model: NgModel) { }

  @HostListener('keyup') onKeyUp() {
    this.changeKeyToLowerCase();
    this.changeValue();
  }

  private changeKeyToLowerCase() {
    this.el.nativeElement.style.textTransform = 'lowercase';
  }

  private format(value: string) {
    return value.toLowerCase();
  }

  private changeValue() {
    if (this.model.value) {
      this.model.viewToModelUpdate(this.format(this.model.value));
    }
  }

}
