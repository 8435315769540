<mat-toolbar>
    <div *ngIf="!production" class="homologAlert">Ambiente de Homologação</div>
    <mat-toolbar-row class="justify-content-between" [ngClass]="{'pl-0': sidebarOpened}">
        <section *ngIf="!sidebarOpened" class="d-flex">
            <a mat-icon-button (click)="toogleSidenav()" type="buttom">
                <mat-icon>menu</mat-icon>
            </a>
        </section>
        <section class="logo" *ngIf="sidebarOpened">
            <img alt="Portal" src="/assets/images/portal-logo.png">
        </section>
        <app-menu-user [user]="user"></app-menu-user>
    </mat-toolbar-row>
</mat-toolbar>
