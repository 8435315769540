import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {User} from '../../../../../../shared/models/user';
import {Router} from '@angular/router';
import {AuthService} from '../../../../../../shared/services/auth.service';
import {InsertionFilterService} from '../../../../../../modules/insertions/insertion-filter.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
    selector: 'app-menu-user',
    templateUrl: 'menu-user.component.html',
    styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent {

    @Input() user: User;
    public show_profile_modal = false;
    public show_alert_modal = false;
    public pisURL = environment.PIS_URL;

    constructor(
        private router: Router,
        private authService: AuthService,
        private insertionFilterService: InsertionFilterService) { }

    toggleProfileModal() {
        this.show_profile_modal = !this.show_profile_modal;
        this.show_alert_modal = false;
    }

    getFirstName() {
        return this.user.name.split(' ')[0];
    }

    navigateToExternalUrl(url: string) {
        window.open(url, '_blank');
    }

    public logout() {
        this.authService.logout().subscribe(() => {
            localStorage.removeItem('token');
            this.insertionFilterService.clearAll();
            this.router.navigate(['/login']);
        });
    }

}
