import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './core/layout.module';
import { APIInterceptor } from './shared/http/interceptors/api-interceptor';
import { RedirectIfAuthenticatedGuard } from './shared/middlewares/redirect-if-authenticated.guard';
import { RedirectIfNotAuthenticatedGuard } from './shared/middlewares/redirect-if-not-authenticated.guard';
import { PiTypeService } from './shared/services/pi-type.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        LayoutModule,
        ToastrModule.forRoot({
            progressBar: true,
            enableHtml: true
        })
    ],
    providers: [
        RedirectIfAuthenticatedGuard,
        RedirectIfNotAuthenticatedGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        },
        PiTypeService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
