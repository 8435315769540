import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProfileService} from '../../services/profile.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorHandler} from '../../http/responses/error-handler';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    selector: 'app-modal-password',
    templateUrl: './modal-password.component.html'
})
export class ModalPasswordComponent implements OnInit {

    formPassword: FormGroup;
    loading: boolean;

    hidePass = true;
    hideConfirm = true;

    private user: User;

    constructor(public dialogRef: MatDialogRef<ModalPasswordComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private profileService: ProfileService,
                private userService: UserService,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private toastr: ToastrService) {
        this.user = data?.user;
        this.createForm();
    }

    ngOnInit(): void {
    }

    public get f() {
        return this.formPassword.controls;
    }

    private createForm() {
        this.formPassword = this.formBuilder.group({
                password: [null, [Validators.required]],
                confirmPassword: [null, [Validators.required]]
            },
            {validators: this.checkPasswords});
    }

    private checkPasswords(group: FormGroup) {
        const password = group.get('password')?.value;
        const confirmPassword = group.get('confirmPassword')?.value;

        return password === confirmPassword ? null : {notSame: true};
    }

    public validatePassword() {
        this.loading = true;
        if (this.formPassword.invalid) {
            this.formPassword.markAsTouched();
            this.loading = false;
            return new ErrorHandler().show('Há dados inválidos no formulário');
        }

        const user = this.user !== undefined ? this.user : this.authService.getAuth();
        user.password = this.f.password.value;
        user.password_confirmation = this.f.confirmPassword.value;
        this.updatePassword(user);

    }

    private updatePassword(user) {
        
        const service = this.user !== undefined ? this.userService : this.profileService;
        service.updatePassword(user).subscribe(() => {
            this.loading = false;
            this.toastr.success('A senha foi alterada com sucesso.', 'Password', {positionClass: 'toast-top-center'});
            this.dialogRef.close();
        }, error => {
            this.loading = false;
            return new ErrorHandler(error).show();
        });
    }

}
