import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Option } from 'src/app/shared/models/option';
import { InsertionExtraOption } from '../models/insertion-extra-option';
import { GoogleAdsAccount } from '../models/google-ads-account';
import { Pixel } from '../models/pixel';
import {DominioPixel} from '../models/pixel-domain';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class InsertionExtraOptionsService {
    @Output()
    public change: EventEmitter<boolean> = new EventEmitter();

    public constructor(private http: HttpClient) {
    }

    owners(data: any, id: number) {
	    const url = `${environment.urlMicro}/insertions/${id}/extras/get_media_owner`;
        return this.http.post<Array<InsertionExtraOption>>(url, data);
    }

    allOwners() {
	    const url = `${environment.urlMicro}/insertions/extras/get_media_owner`;
        return this.http.get<Array<InsertionExtraOption>>(url);
    }

    allAdServers() {
	    const url = `${environment.urlMicro}/insertions/extras/ad_server`;
        return this.http.get<Array<InsertionExtraOption>>(url);
    }

    objectives() {
        const url = `${environment.urlMicro}/extra-options/objectives`;
        return this.http.get<Array<InsertionExtraOption>>(url);
    }

    adservers(data: any, id: number) {
        const url = `${environment.urlMicro}/insertions/${id}/extras/get_adserver`;
        return this.http.post<Array<InsertionExtraOption>>(url, data);
    }

    customers() {
        const url = `${environment.urlMicro}/extra-options/customers`;
        return this.http.get<Array<InsertionExtraOption>>(url);
    }

    accounts(ad_server, fallback?: string) {
        const url = `${environment.url}/extra-options/reports/${(String(ad_server)).toLowerCase()}/platform-accounts`;
        let _default: InsertionExtraOption;

        if (fallback) {
             _default = <InsertionExtraOption>{'name': fallback, 'value': fallback};
        }

        return this.http.get<Array<InsertionExtraOption>>(url).pipe(
            map((accounts: InsertionExtraOption[]) => {
                if (_default && !accounts.find(account => account.value === _default.value)) {
                    accounts.push(_default);
                }
                return accounts;
            }),
            catchError((response, caught) => {
                if (_default && +response.status === 422) {
                    return of([_default]);
                }
                return throwError(response);
            })
        );
    }

    accountsGoogle(ad_server): Observable<Array<GoogleAdsAccount>> {
        const url = `${environment.url}/extra-options/reports/${(String(ad_server)).toLowerCase()}/platform-accounts`;
        return this.http.get<Array<GoogleAdsAccount>>(url);
    }

    pixels() {
        const url = `${environment.url}/extra-options/get_pixels`;
        return this.http.get<Array<Pixel>>(url);
    }

    domainsByPixel(insertionId, id) {
        const url = `${environment.urlMicro}/insertions/${insertionId}/pixel-domains/${id}`;
        return this.http.get<Array<DominioPixel>>(url);
    }

    pixelsById(id) {
        const url = `${environment.urlMicro}/extra-options/get_pixels/${id}`;
        return this.http.get<Array<Pixel>>(url);
    }

    toOptions(data: Array<InsertionExtraOption>): Array<Option> {

        const options: Array<Option> = [];
        for (const i in data) {
            options.push({
                label: data[i].name,
                value: data[i].name
            });
        }
        return options;
    }

    toOptionsId(data: Array<Pixel>): Array<Option> {

        const options: Array<Option> = [];
        for (const i in data) {
            options.push({
                label: data[i].name,
                value: data[i].id
            });
        }
        return options;
    }

    changing() {
        this.change.emit(true);
    }


}
