import {NgModule} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MenuUserComponent} from './layout/components/navbar/components/menu-user/menu-user.component';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DomSanitizer} from '@angular/platform-browser';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import {MatStepperModule} from '@angular/material/stepper';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


@NgModule({
    declarations: [MenuUserComponent],
    imports: [
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatCardModule,
        MatTreeModule,
        MatDialogModule,
        RouterModule,
        MatTabsModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule
    ],
    exports: [
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MenuUserComponent,
        MatListModule,
        MatChipsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatCardModule,
        MatAutocompleteModule,
        MatTreeModule,
        MatDialogModule,
        MatTabsModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatProgressBarModule,
        MatSlideToggleModule,

        NgxMatSelectSearchModule
    ]
})
export class AngularMaterialModule {

    constructor(matIconRegistry: MatIconRegistry,
                domSanitizer: DomSanitizer) {
        const icons = [
            {class: 'santander', path: '/assets/images/icons/santander-logo.svg'}
        ];

        for (const icon of icons) {
            matIconRegistry.addSvgIcon(
                icon.class,
                domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
            );
        }
    }
}
