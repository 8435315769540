import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import {ErrorHandler} from '../http/responses/error-handler';

@Injectable({
    providedIn: 'root'
})

export class MediaModuleService {

    private static mediaModule: Array<string> = new Array<string>();
    private static started: Boolean = false;

    public constructor(private http: HttpClient) {
        if (MediaModuleService.started) {
            return;
        }
        MediaModuleService.started = true;
        if (MediaModuleService.mediaModule.length === 0) {
            this.http.get<Array<any>>(`${environment.url}/media-modules`).subscribe((response: any) => {
                if (!response.error) {
                    MediaModuleService.mediaModule = [];
                    response.map((elem) => MediaModuleService.mediaModule[elem.midia] = elem.modulo);
                } else {
                    return new ErrorHandler().show('Erro ao carregar os módulos.');
                }
            }, error => {
                return new ErrorHandler(error).show();
            });
        }
    }

    getMedias() {
        const url = `${environment.url}/media-modules`;
        return this.http.get<Array<any>>(url);
    }

    isEqual(media: string, module: string) {
        if (module && media) {
            return MediaModuleService.mediaModule[media] === module;
        } else {
            return;
        }
    }

}
