<nav class="sidebar" [ngClass]="{'is-expanded':isExpanded}">
    <div class="sidebar-content">
        <nav class="sidebar-list">
            <a class="sidebar-list-item"
               routerLink="/home" routerLinkActive="sidebar-list-item--activated" id="home">
                <span class="sidebar-list-item--text">
                    <mat-icon class="sidebar-list-item--icon" matTooltip="Home">home</mat-icon>
                    <span *ngIf="isExpanded">Home</span>
                </span>
            </a>
        </nav>
        <nav *ngIf="isAdmin" class="sidebar-list">
            <a #users (click)="toggle(users)" class="sidebar-list-item" id="users">
                <span class="sidebar-list-item--text">
                    <mat-icon class="sidebar-list-item--icon" matTooltip="Usuários">person</mat-icon>
                    <span *ngIf="isExpanded">Usuários</span>
                </span>
                <mat-icon class="sidebar-list-icon" [ngClass]="{'closed': isChildActived(users)}" *ngIf="isExpanded">expand_more</mat-icon>
            </a>

            <nav class="sidebar-group" *ngIf="isExpanded" [ngClass]="{'sidebar-group--opened': isChildActived(users)}">
                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   [routerLink]="'/users'" routerLinkActive="sidebar-list-item--activated" id="btn-users-usuarios" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Gerenciar usuários">settings_applications</mat-icon>
                        <span *ngIf="isExpanded">Gerenciar</span>
                    </span>
                </a>

                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   [routerLink]="'/users/create'" routerLinkActive="sidebar-list-item--activated"
                    id="btn-users-create" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Cadastrar usuarios">add_box</mat-icon>
                        <span *ngIf="isExpanded">Cadastrar</span>
                    </span>
                </a>
                <a class="sidebar-list-item"
                   [routerLink]="'/users/notify'" routerLinkActive="sidebar-list-item--activated" id="btn-users-usuarios" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Notificar usuários por email">outgoing_mail</mat-icon>
                        <span *ngIf="isExpanded">Notificar por e-mail</span>
                    </span>
                </a>
            </nav>
        </nav>

        <nav class="sidebar-list">
            <a #insertions (click)="toggle(insertions)"
               class="sidebar-list-item" id="insertion">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Chaves de Inserção">vpn_key</mat-icon>
                        <span *ngIf="isExpanded">Chaves de Inserção</span>
                    </span>
                <mat-icon class="sidebar-list-icon" *ngIf="isExpanded" [ngClass]="{'closed': isChildActived(insertions)}">expand_more
                </mat-icon>
            </a>

            <nav class="sidebar-group" *ngIf="isExpanded" [ngClass]="{'sidebar-group--opened': isChildActived(insertions)}">
                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   [routerLink]="'/insertions'" routerLinkActive="sidebar-list-item--activated"
                   id="insertion-manager" (click)="collapseSidebar()">
                        <span class="sidebar-list-item--text">
                            <mat-icon class="sidebar-list-item--icon" matTooltip="Gerenciar Chaves">settings_applications</mat-icon>
                            <span *ngIf="isExpanded">Gerenciar</span>
                        </span>
                </a>

                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/insertions/create" routerLinkActive="sidebar-list-item--activated"
                   id="create-insertion" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Cadastrar Chaves">add_box</mat-icon>
                        <span *ngIf="isExpanded">Cadastrar</span>
                    </span>
                </a>

                <!-- <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/insertions/upload-image" routerLinkActive="sidebar-list-item--activated"
                   id="criative-manager" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Gerenciador de Peças">unarchive</mat-icon>
                        <span *ngIf="isExpanded">Gerenciador de Peças</span>
                    </span>
                </a> -->

                <!-- <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/insertions/campaign-manager" routerLinkActive="sidebar-list-item--activated"
                   id="campaing-manager" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Gerenciador de Campanha">dns</mat-icon>
                        <span *ngIf="isExpanded">Gerenciador de Campanha</span>
                    </span>
                </a> -->
            </nav>
        </nav>

        <nav *ngIf="isAdmin" class="sidebar-list">
            <a #trash (click)="toggle(trash)" class="sidebar-list-item" id="btn-lixeira">
                <span class="sidebar-list-item--text">
                    <mat-icon class="sidebar-list-item--icon" matTooltip="Lixeira">delete</mat-icon>
                    <span *ngIf="isExpanded">Lixeira</span>
                </span>
                <mat-icon class="sidebar-list-icon" *ngIf="isExpanded" [ngClass]="{'closed': isChildActived(trash)}">expand_more</mat-icon>
            </a>

            <nav class="sidebar-group" *ngIf="isExpanded" [ngClass]="{'sidebar-group--opened': isChildActived(trash)}">
                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/trash/users" routerLinkActive="sidebar-list-item--activated" id="btn-lixeira-usuarios" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Usuários">account_box</mat-icon>
                        <span *ngIf="isExpanded">Usuários</span>
                    </span>
                </a>

                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/trash/insertions" routerLinkActive="sidebar-list-item--activated"
                   id="btn-lixeira-chaves-insercao" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon">vpn_key</mat-icon>
                        <span *ngIf="isExpanded">Chaves de Inserção</span>
                    </span>
                </a>
            </nav>
        </nav>

        <nav *ngIf="isAdmin" class="sidebar-list">

            <a #initiatives (click)="toggle(initiatives)" class="sidebar-list-item" id="initiative">
                <span class="sidebar-list-item--text">
                    <mat-icon class="sidebar-list-item--icon" matTooltip="Configurações">admin_panel_settings</mat-icon>
                    <span *ngIf="isExpanded">Configurações</span>
                </span>
                <mat-icon class="sidebar-list-icon" *ngIf="isExpanded" [ngClass]="{'closed': isChildActived(initiatives)}">expand_more
                </mat-icon>
            </a>

            <nav class="sidebar-group" *ngIf="isExpanded" [ngClass]="{'sidebar-group--opened': isChildActived(initiatives)}">
                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/administrative/initiatives" routerLinkActive="sidebar-list-item--activated"
                   id="btn-administrative-initiative" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Iniciativas">list_alt</mat-icon>
                        <span *ngIf="isExpanded">Iniciativas</span>
                    </span>
                </a>''

                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                    routerLink="/administrative/vehicles" routerLinkActive="sidebar-list-item--activated"
                    id="vehicles-manager" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon" matTooltip="Gerenciador de Veículos">dns</mat-icon>
                        <span *ngIf="isExpanded">Gerenciador de Veículos</span>
                    </span>
                </a>
            </nav>
        </nav>

        <!-- <nav *ngIf="authUrl" class="sidebar-list">
            <a #platforms (click)="toggle(platforms)"
               class="sidebar-list-item" id="platforms">
                <span class="sidebar-list-item--text">
                    <mat-icon class="sidebar-list-item--icon" matTooltip="Plataformas">cloud_queue</mat-icon>
                    <span *ngIf="isExpanded">Plataformas</span>
                </span>
                <mat-icon class="sidebar-list-icon" *ngIf="isExpanded" [ngClass]="{'closed': isChildActived(platforms)}">expand_more
                </mat-icon>
            </a>

            <nav class="sidebar-group" *ngIf="isExpanded" [ngClass]="{'sidebar-group--opened': isChildActived(platforms)}">
                <a href="{{authUrl?.google}}" class="sidebar-list-item"
                   id="btn-platforms-google" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <i aria-hidden="true" class="fab fa-google sidebar-list-item--icon mx-2" matTooltip="GoogleAds"></i>
                        <span *ngIf="isExpanded">GoogleAds</span>
                    </span>
                </a>
                <a href="{{authUrl?.facebook}}" class="sidebar-list-item"
                   id="btn-platforms-facebook" (click)="collapseSidebar()">
                    <span class="sidebar-list-item--text">
                        <i aria-hidden="true" class="fab fa-facebook-f sidebar-list-item--icon mx-2" matTooltip="Facebook Ads"></i>
                        <span *ngIf="isExpanded">Facebook Ads</span>
                    </span>
                </a>
            </nav>
        </nav> -->
        <!-- <nav class="sidebar-list">
            <a #support (click)="toggle(support)" class="sidebar-list-item" id="btn-support">
                    <span class="sidebar-list-item--text">
                        <mat-icon class="sidebar-list-item--icon">help</mat-icon> Suporte
                    </span>
                <mat-icon class="sidebar-list-icon" [ngClass]="{'closed': isChildActived(support)}">expand_more
                </mat-icon>
            </a>

            <nav class="sidebar-group" [ngClass]="{'sidebar-group--opened': isChildActived(support)}">
                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/support/documentation" routerLinkActive="sidebar-list-item--activated"
                   id="btn-support-documentation">
                        <span class="sidebar-list-item--text">
                            <mat-icon class="sidebar-list-item--icon">book</mat-icon> Documentação
                        </span>
                </a>

                <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-list-item"
                   routerLink="/support/email" routerLinkActive="sidebar-list-item--activated"
                   id="btn-support-email">
                        <span class="sidebar-list-item--text">
                            <mat-icon class="sidebar-list-item--icon">mail</mat-icon> Solicitações
                        </span>
                </a>
            </nav>
        </nav> -->
    </div>

    <footer class="sidebar-footer pb-2" [ngClass]="{'justify-content-center': !isExpanded}">
        <!--
        <button mat-icon-button type="button" (click)="close()">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        -->
        <button class="button-expansion" mat-icon-button type="button" (click)="isExpanded = !isExpanded" [ngClass]="{'closed':isExpanded}">
            <mat-icon matTooltip="Esconder/expandir menu">arrow_forward_ios</mat-icon>
        </button>
    </footer>

</nav>
