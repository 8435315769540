import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class PlatformService {

    constructor(private http: HttpClient) { }

    getAuthorizationUrl() {
        return this.http.get<{ url: { [key: string]: string } }>(`${environment.url}/platform/auth/url`);
    }

    save(platform: string, params: any) {
        return this.http.get(`${environment.url}/platform/auth/${platform}/response`, {
            params: params
        });
    }
}
