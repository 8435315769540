import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
    selector:    'app-clear',
    templateUrl: './layout-logout.component.html',
    styleUrls: ['layout-logout.component.scss']
})

export class LayoutLogoutComponent {
    public production = environment.production;
}
