<span *ngIf="direction === ''" (click)="change('asc')">
    <mat-icon _ngcontent-c0="" class="sort-icon">arrow_drop_up</mat-icon>
    <mat-icon _ngcontent-c0="" class="sort-icon">arrow_drop_down</mat-icon>
</span>

<span *ngIf="direction === 'desc'" (click)="change()">
    <mat-icon _ngcontent-c0="" class="sort-icon">arrow_drop_up</mat-icon>
    <mat-icon _ngcontent-c0="" class="sort-icon sort-icon-disabled">arrow_drop_down</mat-icon>
</span>

<span *ngIf="direction === 'asc'" (click)="change('desc')">
    <mat-icon _ngcontent-c0="" class="sort-icon sort-icon-disabled">arrow_drop_up</mat-icon>
    <mat-icon _ngcontent-c0="" class="sort-icon">arrow_drop_down</mat-icon>
</span>
