import {Component} from '@angular/core';

@Component({
    selector:    'app-layout-logged',
    templateUrl: './layout-logged.component.html',
    styleUrls: ['./layout-logged.component.scss']
})

export class LayoutLoggedComponent {

}
