import { Component } from '@angular/core';
import { MediaModuleService } from './shared/services/media-module.service';
import Handsontable from 'handsontable';
import {HttpClient} from '@angular/common/http';

function customRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    // Optionally include `BaseRenderer` which is responsible for adding/removing CSS classes to/from the table cells.

    if (hotInstance.getDataAtCell(row, 0) == null || hotInstance.getDataAtCell(row, 0) == undefined) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        return ;
    }
    value = value === 1 ? '<span class="text-success"> Sim </span>' :  '<span class="text-danger"> Não </span>' ;
    td.style.backgroundColor = value === 1 ? 'white' : 'red';
    // td.html( isSync);
    Handsontable.renderers.HtmlRenderer.apply(this, [hotInstance, td, row, column, prop, value, cellProperties]);
    // ...your custom logic of the renderer
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})



export class AppComponent {
    constructor(private mediaModule: MediaModuleService, private http: HttpClient) {
        const mediaModuleService: MediaModuleService =  new MediaModuleService(http);

        Handsontable.renderers.registerRenderer('is_synchro_field', customRenderer);
    }
}
