import Swal from 'sweetalert2';
import { WarningHandler } from './warning-handler';

export class ErrorHandler {

    public error: any;

    public title = 'Atenção!';

    public error_type: any = 'error';

    public constructor(error: any = false) {
        if (error) {

            this.error = error;
        }
    }

    public show(message: string = '') {

        if (message) {
            return Swal.fire(this.title, message, this.error_type);
        }

        if (this.error.status === 401) {
            return Swal.fire(this.title, 'Você não tem permissão para acessar esta página e/ou ação.', this.error_type);
        }

        if (this.error.status === 405) {
            return Swal.fire(this.title, 'Método não autorizado para seu usuário.', this.error_type);
        }

        if (this.error.status === 422) {
            if (this.error['error']['errors']) {
                const stack = this.error.error.errors;

                if (Array.isArray(stack)) {
                    const message = this.formatMessage(stack);
                    return Swal.fire(this.title, message, this.error_type);
                }

                let prop = Object.keys(stack)[0];
                return Swal.fire(this.title, stack[prop][0], this.error_type);
            }
            return Swal.fire(this.title, 'Método não autorizado para seu usuário.', this.error_type);
        }

        if (this.error.status === 429) {
            return Swal.fire(this.title, 'Muitas requisições realizadas, aguarde um momento.', this.error_type);
        }

        if (this.error.status === 500) {
            return Swal.fire(this.title, 'Erro interno, contate o administrador.', this.error_type);
        }

        if ('old-version' === this.error) {
            return Swal.fire(this.title,
                'O registro que você está tentando acessar está bloqueado pois possui a taxonomia no padrão antigo.', this.error_type);
        }

        if (this.error.error.warnings) {
            return new WarningHandler(this.error).show();
        }

        if (this.error.error.errors) {
            let errors = Object.values(this.error.error.errors);
            let error = typeof errors[0] === 'string' ? errors[0] : errors[0][0];
            return Swal.fire(this.title, error, this.error_type);
        }

    }

    private formatMessage(errors: any[]) {
        let message = '';
        errors.forEach(err => {
            if (err.message) {
                message += err.message + '<br>';
            } else {
                message += err + '<br>';
            }
        });
        return message;
    }

}
