import {Company} from './company';
import {AccessLevelGroup} from './access-level-group';

export class User {

    public id: number;
    public name: string;
    public email: string;
    public password: string;
    public password_confirmation: string;
    public cpf: string;
    public office: string;
    public phone: string;
    public token: string;
    public reset_password_token: string;
    public status: boolean;
    public company_id: number;
    public created_at: string;
    public updated_at: string;
    public deleted_at: string;
    public google_account: string;
    public facebook_account: string;
    public company: Company = new Company();
    public access_level_groups: Array<AccessLevelGroup> = [];

}
