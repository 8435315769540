import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccessLevelGroup } from '../models/access-level-group';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})

export class AccessLevelService {
    public constructor(private http: HttpClient) {
    }

    is_accessible(page: string, action: string = '') {
        const url = `${environment.url}/access-levels/exists?page=${page}&action=${action}`;
        return this.http.get<boolean>(url);
    }

    get_user_groups() {
        const url = `${environment.url}/access-levels/user_groups`;
        return this.http.get<boolean>(url);
    }

    all() {
        const url = `${environment.url}/access-levels`;
        return this.http.get<Array<AccessLevelGroup>>(url);
    }

    save(user: User) {
        const url = `${environment.url}/access-levels`;
        return this.http.post(url, user);
    }

    /**
     * Returns a Promise that always resolves with true or false
     * depending if the user can access the given action.
     *
     * @param string page
     * @param string controllerAction
     * @return Promise<boolean>
     */
    can(page: string, controllerAction: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const action = 'App\\Http\\Controllers\\Api\\'  + controllerAction;

            this.is_accessible(page, action).subscribe((data) => {
                if (data) {
                    resolve(true);
                }

                resolve(false);
            });
        });
    }
}
