import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Option } from 'src/app/shared/models/option';
import { environment } from 'src/environments/environment';
import { Pagination } from '../models/pagination';
import { User } from '../models/user';

declare var require: any;
const httpBuildQuery = require('http-build-query');

@Injectable({
    providedIn: 'root'
})

export class UserService {
    public constructor(private http: HttpClient) {
    }

    find(id: number) {
        const url = `${environment.url}/users/${id}`;
        return this.http.get<User>(url);
    }

    all() {
        const url = `${environment.urlMicro}/users/all`;
        return this.http.get<Array<User>>(url);
    }

    paginate(page: number = 1, search: any, sort: any) {
        const url = `${environment.urlMicro}/users?page=${page}&${httpBuildQuery({search: search})}&${httpBuildQuery({sort: sort})}`;

        return this.http.get<Pagination>(url);
    }

    create(user: User) {
        const url = `${environment.url}/users`;

        return this.http.post<User>(url, user);
    }

    update(user: User, send_email: boolean = false) {
        const url = `${environment.url}/users/${user.id}?send_email=${send_email}`;
        return this.http.put(url, user);
    }

    updatePassword(user: User) {
        const url = `${environment.urlMicro}/users/password`;
        return this.http.put(url, user);
    }

    destroy(id: number) {
        const url = `${environment.urlMicro}/users/${id}`;
        return this.http.delete(url);
    }

    toOptions(data: Array<User>): Array<Option> {
        const options: Array<Option> = [];

        for (const user of data) {
            options.push({
                label: user.name + ' - ' + user.company.name,
                value: String(user.id)
            });
        }

        return options;
    }

}
