import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Option } from 'src/app/shared/models/option';
import {FormControl, Validators} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {AreaMediaService} from '../../services/redshift/area-media.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-mat-select',
  templateUrl: './mat-select.component.html'
})
export class MatSelectComponent implements OnInit, OnDestroy {

    @Output() changeEmitter = new EventEmitter<any>();
    @Input() label: string;
    @Input() options: Array<Option> = [];
    @Input() set valueControl(value: string) {
        if (value) {
            this.control.setValue(value);
            this.control.updateValueAndValidity();
        }
    }
    @Input() set disable(disable: boolean) {
        if (disable) {
            this.inputControl.disable();
            this.control.disable();
        }
    }

    public inputControl = new FormControl();
    public control = new FormControl(null, [Validators.required]);

    public filteredOptions: ReplaySubject<Option[]> = new ReplaySubject<Option[]>(1);
    protected _onDestroy = new Subject<void>();


    constructor(private areaMediaService: AreaMediaService) { }

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filter();
            });
        this.filteredOptions.next(this.options.slice());
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected filter() {
        if (!this.options) {
            return;
        }

        let search = this.inputControl.value;
        if (!search) {
            this.filteredOptions.next(this.options.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOptions.next(
            this.options.filter(bank => bank.label.toLowerCase().includes(search))
        );
    }

    public selected() {
        this.changeEmitter.emit(this.control.value);
    }

}
