export enum FieldExtraControl {
    MEDIA_OWNER = 0,
    ADSERVERPLATFORM = 1,
    CUSTOMER = 2,
    ROTATIVE = 3,
    SCREENS = 4,
    FACES = 5,
    ACCOUNT = 6,
    PIXEL = 7,
    DOMAIN = 8,
}
