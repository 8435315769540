<mat-form-field class="w-100" [appearance]="accountControl?.invalid && accountControl?.touched ? 'outline' : 'legacy'">
    <mat-select
        [formControl]="accountControl"
        [placeholder]="label" (selectionChange)="selected()"
        [required]="required">
        <mat-option>
            <ngx-mat-select-search [formControl]="inputControl"
                                   clearSearchInput="false"
                                   placeholderLabel="Pesquisar"
                                   noEntriesFoundLabel="Nenhum item encontrado"
                                   enableClearOnEscapePressed="false"
            ></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
            {{option.label}}
        </mat-option>
        <mat-option [value]="accountControl?.value" *ngIf="accountControl?.value" style="display: none">
            {{accountControl?.value}}
        </mat-option>
    </mat-select>

    <mat-icon matSuffix [matTooltip]="error" class="color-danger cursor--help" *ngIf="error">error</mat-icon>
    <mat-error *ngIf="accountControl?.invalid">Campo obrigatório</mat-error>
</mat-form-field>
