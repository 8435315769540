<a mat-button [mat-menu-trigger-for]="userDetailsMenu" class="text-h4 align-items-baseline">
    <mat-icon matListIcon class="mr-1">person</mat-icon>{{ getFirstName() }}
</a>
<mat-menu #userDetailsMenu="matMenu" class="p-0">
    <!--*ngIf="show_profile_modal"-->
    <div class="portal-user-menu-header">
        <div>
            <h2>{{ user.name }}</h2>
            <h3 class="m-0">{{ user.office }}</h3>
        </div>
    </div>
    <mat-nav-list>
        <mat-list-item [routerLink]="['/profile']" id="profile">
            <mat-icon matListIcon>account_circle</mat-icon>
            <a matLine>Meu Perfil</a>
        </mat-list-item>

        <mat-list-item [routerLink]="['/downloads']" id="download">
            <mat-icon matListIcon>file_download</mat-icon>
            <a matLine>Download de materiais</a>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item [routerLink]="['/support']" id="support">
            <mat-icon matListIcon>contact_support</mat-icon>
            <a matLine>Suporte</a>
        </mat-list-item>

        <mat-list-item [routerLink]="['/maintenance-flow']" id="os">
            <mat-icon matListIcon>description</mat-icon>
            <a matLine>Solicitações</a>
        </mat-list-item>

<!--         <mat-list-item (click)="navigateToExternalUrl(pisURL)" id="upload">
            <mat-icon matListIcon>file_upload</mat-icon>
            <a matLine>Envio de relatórios</a>
        </mat-list-item> -->

        <mat-divider></mat-divider>

        <mat-list-item (click)="logout()" id="exit">
            <mat-icon matListIcon>exit_to_app</mat-icon>
            <a matLine>sair do sistema</a>
        </mat-list-item>
    </mat-nav-list>
</mat-menu>
