import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

declare var document: any;

@Injectable()

export class APIInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.handlerRequest(request));
    }

    private handlerRequest(request: HttpRequest<any>) {

        const token = localStorage.getItem('token');
        let api_request;

        if (token) {
            api_request = request.clone({
                headers: request.headers.set('token', token)
            });
        }

        return request.clone(api_request);
    }

}
