import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import { ErrorHandler } from 'src/app/shared/http/responses/error-handler';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
const crypto = require('crypto-js');

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    public constructor(private http: HttpClient) {
    }

    setAuth(user: User) {
        localStorage.setItem('token', user.token);
        localStorage.setItem('user', crypto.AES.encrypt(JSON.stringify(user), environment.SECRET_KEY));
    }

    getAuth(): User {
        const bytes = crypto.AES.decrypt(localStorage.getItem('user').toString(), environment.SECRET_KEY);
        return JSON.parse(bytes.toString(crypto.enc.Utf8));
    }

    login(user: User) {
        const url = `${environment.url}/auth/login`;

        return this.http.post<User>(url, user).pipe(
            tap(response => {

                localStorage.setItem('userType', response.company_id.toString());
                this.setAuth(response);
            },
            error => {
                return new ErrorHandler().show('Usuário não encontrado ou cadastro pendente de ativação.');
            }
        ));
    }

    logout() {
        const url = `${environment.url}/auth/logout`;
        return this.http.get(url);
    }

    register(user: User) {
        const url = `${environment.url}/auth/register`;
        return this.http.post(url, user);
    }

    forgotPassword(user: User) {
        const url = `${environment.url}/auth/forgot-password`;
        return this.http.post(url, user);
    }

    changePassword(user: User) {
        const url = `${environment.url}/auth/change-password`;
        return this.http.post(url, user);
    }

    checkAuth(token: string) {
        const url = `${environment.url}/auth/check-auth?token=${token}`;
        return this.http.get<User | boolean>(url);
    }
}
