import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})

export class ProfileService {
    public constructor(private http: HttpClient) {
    }

    get() {
        return this.http.get<User>(`${environment.url}/profiles`);
    }

    update(user: User) {
        const url = `${environment.url}/profiles/${user.id}`;
        return this.http.put(url, user);
    }

    updatePassword(user: User) {
        const url = `${environment.url}/profiles/password`;
        return this.http.put(url, user);
    }

    destroy(user: User) {
        const url = `${environment.url}/profiles/${user.id}`;
        return this.http.delete(url);
    }
}
