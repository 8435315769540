import {Component, Inject, OnInit, } from '@angular/core';
import {ErrorHandler} from 'src/app/shared/http/responses/error-handler';
import {GoogleAdsAccount} from 'src/app/shared/models/google-ads-account';
import {InsertionExtraOptionsService} from 'src/app/shared/services/insertion-extra-options.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-select-google-account',
    templateUrl: './modal-select-google-account.component.html'
})

export class ModalSelectGoogleAccountComponent implements OnInit {

    public loading: boolean;
    private listAccounts: Array<GoogleAdsAccount> = [];
    public googleAccounts: Array<GoogleAdsAccount> = [];

    public searchName: string;

    constructor(public dialogRef: MatDialogRef<ModalSelectGoogleAccountComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.listAccounts = data.listAccounts;
        this.googleAccounts = data.listAccounts;
    }

    ngOnInit() { }

    public search() {
        if (this.searchName) {
            this.googleAccounts = this.listAccounts.filter(item => {
                return item.name.toLowerCase().includes(this.searchName.toLowerCase());
            });
        }
        if (this.searchName === '') {
            this.googleAccounts = this.listAccounts;
        }
    }

    public selectAccount(account: GoogleAdsAccount) {
        this.dialogRef.close(account.name);
    }

}
