import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InsertionExtraOptionsService} from 'src/app/shared/services/insertion-extra-options.service';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {FieldExtraControl} from '../../../modules/insertions/modules/insertion-update/components/extras/components/components/fields/FieldExtraControl';
import { Option } from 'src/app/shared/models/option';
import {ReplaySubject} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-account-field',
    templateUrl: './account-field.component.html'
})
export class AccountFieldComponent implements OnInit, OnDestroy {

    @Output() changeEmitter = new EventEmitter<any>();
    @Input() public label = 'Conta';
    @Input() required = true;
    @Input() set setAdServer(adServer: string) {
        if (adServer) {
            this.adserver = adServer;
            this.getAccounts();
        }
    }
    @Input() set setPlatform(platform: string) {
        if (platform) {
            this.platform = platform;
            this.getAccounts();
        }
    }
    @Input() accountControl: FormControl;
    public inputControl = new FormControl(null);
    public error: string;
    public name = 'account';
    public help = 'Conta a ser vinculada';

    private platform: string;
    private adserver: string;
    protected accounts: Array<Option> = [];
    public filteredOptions: ReplaySubject<Option[]> = new ReplaySubject<Option[]>(1);
    protected _onDestroy = new Subject<void>();

    constructor(private insertionExtraOptionsService: InsertionExtraOptionsService,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filter();
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected filter() {
        if (!this.accounts) {
            return;
        }

        let search = this.inputControl.value;
        if (!search) {
            this.filteredOptions.next(this.accounts.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOptions.next(
            this.accounts.filter(bank => bank.label.toLowerCase().includes(search))
        );
    }

    private getAccounts() {
        if (this.adserver && this.platform) {
            this.insertionExtraOptionsService.accounts(this.adserver).subscribe(data => {
                this.accounts = this.insertionExtraOptionsService.toOptions(data);
                this.filteredOptions.next(this.accounts.slice());
            }, err => {               
                this.accountControl.disable();
                this.error = err.error ? err.error.errors[0] : err.mesage;
                this.toastr.error(err.error.errors[0], 'FacebookAds:', {
                    timeOut: 15000
                });
            });
            
        }
    }

    public selected() {
        this.changeEmitter.emit({control: FieldExtraControl.ACCOUNT, value: this.accountControl.value});
    }
}
