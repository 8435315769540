<mat-form-field class="w-100">
    <mat-select [formControl]="control" [placeholder]="label" (selectionChange)="selected()">
        <mat-option>
            <ngx-mat-select-search [formControl]="inputControl"
                                   placeholderLabel="Pesquisar"
                                   noEntriesFoundLabel="Nenhum item encontrado"
                                   enableClearOnEscapePressed="false"
            ></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
            {{option.label}}
        </mat-option>
    </mat-select>
</mat-form-field>
