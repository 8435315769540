import {Component, Input} from '@angular/core';

@Component({
    selector:    'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {
    @Input() statusLoad: boolean;
    @Input() fullPage: boolean;
    @Input() showLabel: boolean;

    constructor() { }
}
