import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html'
})
export class ButtonBackComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

    public backToList() {
        this.location.back();
    }

}
