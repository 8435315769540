import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {AccessLevelService} from 'src/app/shared/services/access-level.service';
import {PlatformService} from "../../../../shared/services/auth-platform.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {

    @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Input() sidebarOpened: boolean;

    private child: HTMLAnchorElement = null;
    public authUrl = undefined;
    public isAdmin = false;
    public isExpanded = false;

    constructor(private accessLevelService: AccessLevelService,
                private platformService: PlatformService) {
        this.checkAcessPrivilages();
        this.authorizationUrl();
    }

    toggle(el) {
        if(!this.isExpanded){
            this.isExpanded = !this.isExpanded
        }
        if (this.child === el) {
            this.child = null;
        } else {
            this.child = el;
        }
    }

    isChildActived(el) {
        const list = el.nextElementSibling.children;

        for (let i = 0; i < list.length; i++) {
            if (list[i].classList.contains('sidebar-list-item--activated')) {
                return true;
            }
        }

        if (this.child === el) {
            return true;
        }

        return false;
    }

    async authorizationUrl() {
        try {
            const res = await this.platformService.getAuthorizationUrl().toPromise();
            this.authUrl = { ...res.url };
        } catch (err) {
            console.error('authorizationUrl', err.message)
        }
    }

    collapseSidebar(){
        this.isExpanded = false
    }
    public checkAcessPrivilages() {
        const access_level: any = {
            page: 'insertions',
            action: 'App\\Http\\Controllers\\Api\\InsertionController@forceStore'
        };
        this.accessLevelService.is_accessible(access_level.page, access_level.action).subscribe((data) => {
            if (data) {
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }
        });
    }

    close() {
        this.toggleSidebar.emit();
    }
}
